import { call, put, takeLatest } from 'redux-saga/effects'
import { get } from 'lodash'

import {
  REQUEST_LOGIN,
  REQUEST_ERROR,
  ACTION_REQUEST,
  LOGIN_SUCCESS,
  REQUEST_APPROVE_SUCCESS,
  REQUEST_APPROVE,
  REQUEST_PDF,
  REQUEST_PDF_SUCCESS,
  INIT_RECORD,
  REQUEST_GET_RECORD,
} from '../types'

import authApi from '../../api/auth'
import recordAPI from '../../api/record'
import { client } from '../../api/useApi'

function* login({ payload }) {
  try {
    yield put({ type: ACTION_REQUEST })

    const response = yield call(authApi(client).authorized, payload)
    const { data } = response
    yield put({ type: LOGIN_SUCCESS, record: data })
  } catch (error) {
    yield put({
      type: REQUEST_ERROR,
      error: get(error, 'response.data.message', error.message),
    })
  }
}

function* approve({ payload }) {
  try {
    yield put({ type: ACTION_REQUEST })

    const response = yield call(recordAPI(client).approve, payload)
    const { data } = response
    yield put({ type: REQUEST_APPROVE_SUCCESS, record: data })
  } catch (error) {
    yield put({
      type: REQUEST_ERROR,
      error: get(error, 'response.data.message', error.message),
    })
  }
}

function* getPdf({ payload }) {
  try {
    yield put({ type: ACTION_REQUEST })

    const response = yield call(recordAPI(client).pdf, payload)
    const { data } = response;
    const { pdfURL } = data
    window.location.href = pdfURL;
    yield put({ type: REQUEST_PDF_SUCCESS })
  } catch (error) {
    yield put({
      type: REQUEST_ERROR,
      error: get(error, 'response.data.message', error.message),
    })
  }
}

function* getRecord({ payload }) {
  try {
    yield put({ type: ACTION_REQUEST })

    const response = yield call(recordAPI(client).get, payload)
    const { data } = response
    yield put({ type: INIT_RECORD, record: data })
  } catch (error) {
    yield put({
      type: REQUEST_ERROR,
      error: get(error, 'response.data.message', error.message),
    })
  }
}

export default function* mainWatcher() {
  yield takeLatest(REQUEST_LOGIN, login)
  yield takeLatest(REQUEST_APPROVE, approve)
  yield takeLatest(REQUEST_PDF, getPdf)
  yield takeLatest(REQUEST_GET_RECORD, getRecord)
}
