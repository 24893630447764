import React from 'react'
import ReactDOM from 'react-dom'
import { applyMiddleware, createStore } from 'redux'
import { Provider } from 'react-redux'
import createSagaMiddleware from 'redux-saga'

import * as serviceWorker from './serviceWorker'
import { ToastContainer, Flip } from 'react-toastify'

import rootSaga from './store/saga'
import reducers from './store/reducers'

import App from './App'
import './styles/index.scss'
import 'react-toastify/dist/ReactToastify.css'
import { Container } from 'react-bootstrap'
import useToken from './context/useToken'

const sagaMiddleware = createSagaMiddleware()
const store = createStore(reducers(), {}, applyMiddleware(sagaMiddleware))
sagaMiddleware.run(rootSaga)

const AppContainer = () => {
  useToken()
  return (
    <Provider store={store}>
      <Container className='container-toast-popup'>
        <ToastContainer
          position='top-right'
          closeButton={false}
          autoClose={4000}
          hideProgressBar
          newestOnTop
          closeOnClick
          pauseOnVisibilityChange
          draggable={false}
          pauseOnHover
          transition={Flip}
        />
      </Container>
      <App />
    </Provider>
  )
}

ReactDOM.render(<AppContainer />, document.getElementById('root'))
serviceWorker.register()
