import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { Image, Modal } from 'react-bootstrap'
import { loadStripe } from '@stripe/stripe-js'
import { Elements, ElementsConsumer } from '@stripe/react-stripe-js'

import { STRIPE_PUBLISHABLE_KEY } from '../../config'
import PayWithCard from './PayWithCard'

import './paymentModal.scss'
import { useDispatch, useSelector } from 'react-redux'
import { isDepositPayment } from '../../utils/payment'
import Receipt from './receipt'
import { DOCUMENT_TYPE } from '../../utils/constant'
import { get } from 'lodash'
import { REQUEST_GET_RECORD } from '../../store/types'
import { formatAmount } from '../../utils/currency'

const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY)

const PayInvoiceComponent = ({ hideModal, open }) => {
  const dispatch = useDispatch()
  const [paymentData, setPaymentData] = useState({
    isPaymentSuccess: false,
    data: {},
  })

  const { record } = useSelector(store => {
    return store.main
  })
  const { number, client, payment, total, metadata } = record
  const { document, company } = metadata
  const { deposit = 0, amount = 0 } = payment

  const isDeposit = isDepositPayment(record)

  const handleClose = () => {
    dispatch({ type: REQUEST_GET_RECORD, payload: { recordId: record._id } })
    setPaymentData({
      isPaymentSuccess: false,
      data: {},
    })
    hideModal(false)
  }

  const handleSubmitCard = paymentData => {
    setPaymentData({
      isPaymentSuccess: true,
      data: paymentData,
    })
  }

  const InjectedCheckoutForm = ({ setPaymentSuccess, onSubmit }) => (
    <ElementsConsumer>
      {({ stripe, elements }) => {
        if (elements) {
          return (
            <PayWithCard
              stripe={stripe}
              elements={elements}
              handleResult={onSubmit}
            />
          )
        } else return ''
      }}
    </ElementsConsumer>
  )

  return (
    <div className='row'>
      <Modal
        show={open}
        onHide={handleClose}
        dialogClassName='pay-invoice-modal modal-fullscreen-mobile large-modal'
        aria-labelledby='example-custom-modal-styling-title'
      >
        <Modal.Header>
          <img
            onClick={handleClose}
            className='close-icon hide-mobile'
            src='/assets/images/icons/close.svg'
            alt=''
          />
        </Modal.Header>
        <Modal.Body>
          {paymentData.isPaymentSuccess && (
            <Receipt handleClose={handleClose} paymentData={paymentData.data} />
          )}
          {!paymentData.isPaymentSuccess && (
            <div className='container'>
              <div className='row d-lex align-items-center'>
                <div className='col-md-8 invoice-info'>
                  <div className='logo-area'>
                    <img
                      className='invoice-logo'
                      src={get(
                        company.business,
                        'logo',
                        '/assets/images/default.svg'
                      )}
                      alt='MTRX'
                    />
                    <div className='h4 font-weight-bold text-capitalize'>
                      {company.name}
                    </div>
                    <div className='h5 font-weight-bold m-0 mt-1 text-uppercase'>
                      {DOCUMENT_TYPE[document.toUpperCase()]} #{number}
                    </div>
                    <div className='font-875 mb-4'>
                      for: {client.firstName} {client.lastName}
                    </div>

                    <div className='h5 font-weight-bold pt-4'>
                      {isDeposit
                        ? `$${formatAmount(deposit / 100)} Deposit`
                        : `$${formatAmount(
                            (total - amount) / 100
                          )} Balance Due`}
                    </div>
                    <div className='font-875'>
                      {isDeposit &&
                        `Estimate Total: $${formatAmount(total / 100)}`}
                      {!isDeposit &&
                        `Job Total: $${formatAmount(
                          total / 100
                        )} | Amount paid: $${formatAmount(amount / 100)}`}
                    </div>
                  </div>

                  <img
                    className='w-100'
                    src='/assets/images/test/pay-invoice-large.svg'
                    alt=''
                  />

                  <div className='footer-area'>
                    <div className='title'>
                      <span>
                        Powered by <span className='title-point'>Stripe</span>
                      </span>
                      <div className='font-75'>
                        This charge will appear as “Stripe Transfer” on your
                        statement
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-md-4 '>
                  <div className='pay-with-card'>
                    <div className='h5 font-weight-bold text-center'>
                      <Image
                        className='mr-2'
                        src='/assets/images/icons/credit-card.svg'
                        alt=''
                      />
                      Payment info
                    </div>
                    <div className='card-form'>
                      <Elements stripe={stripePromise}>
                        <InjectedCheckoutForm onSubmit={handleSubmitCard} />
                      </Elements>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </div>
  )
}

PayInvoiceComponent.propTypes = {
  open: PropTypes.bool.isRequired,
  hideModal: PropTypes.func.isRequired,
}

PayInvoiceComponent.defaultProps = {
  // vehicle: null,
}

export default PayInvoiceComponent
