import React from 'react'
import { Row, Col, Image, Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { get } from 'lodash'

import { approve } from '../../store/actions/main'
import './companyInfo.scss'

import {
  isDepositPayment,
  isNoDepositPayment,
  isPaidPayment,
  isPartialPaidPayment,
  isUnpaidPayment,
} from '../../utils/payment'
import { formatDate } from '../../utils/dateTime'
import { formatAmount } from '../../utils/currency'

const CompanyInfo = ({ setShowPayInvoice }) => {
  const { record } = useSelector(store => {
    const { main } = store
    return main
  })

  const { metadata, payment, total } = record
  const company = get(metadata, 'company')
  const { deposit = 0, amount = 0 } = payment
  const isDeposit = isDepositPayment(record)
  const isNoDeposit = isNoDepositPayment(record)

  const isPaid = isPaidPayment(record)
  const isPartialPaid = isPartialPaidPayment(record)
  const isUnpaid = isUnpaidPayment(record)
  const dispatch = useDispatch()
  const approveRecord = () => {
    const { _id } = record
    dispatch(approve({ recordId: _id }))
  }

  const address = get(company.business, 'address.lines', []).filter(line => {
    const lineStr = line || ''
    return lineStr.trim() !== ''
  })

  const city = get(company.business, 'address.city', '')
  const state = get(company.business, 'address.state', '')
  const zip = get(company.business, 'address.zip', '')
  const logo = get(company.business, 'logo', '')
  const showAddress = [...address, city, state, zip].join(', ')
  const userID = get(company.stripe, 'userID', '')

  return (
    <div className='company-info'>
      <Row className='align-items-center'>
        <Col md={3}>
          <div className='company-info-logo'>
            {logo && <Image className='w-100' src={logo} alt='' />}
          </div>
        </Col>
        <Col md={5} className='company-contact'>
          <p className='h4 font-weight-bold'>{company.name}</p>
          {get(company, 'business.phone', '') && (
            <div className='contact'>
              <Image src='/assets/images/icons/phone.svg' alt='' />
              <span className='contact-des'>
                {get(company, 'business.phone', '')}
              </span>
            </div>
          )}

          {get(company, 'business.email', '') && (
            <div className='contact'>
              <Image src='/assets/images/icons/email.svg' alt='' />
              <span className='contact-des'>
                {get(company, 'business.email', '')}
              </span>
            </div>
          )}
          {address.length > 0 && (
            <div className='contact'>
              <Image src='/assets/images/icons/location.svg' alt='' />
              <span className='contact-des'>{showAddress}</span>
            </div>
          )}
        </Col>
        <Col md={4}>
          <div className='company-invoice'>
            {/* invoice no deposit and unpaid */}
            {(isUnpaid || isPartialPaid) && (
              <>
                <Button className='btn-payment' variant='warning'>
                  <Image
                    className='mr-2'
                    src='/assets/images/icons/warning.svg'
                  />
                  Balance Due: ${formatAmount((total - amount) / 100)}
                </Button>
                {amount > 0 && (
                  <div className='notice'>
                    <span className='font-weight-600'>
                      {`${formatDate(payment.dateCreated)} $${formatAmount(
                        amount / 100
                      )} `}
                    </span>
                    Payment Received
                  </div>
                )}
                {userID && (
                  <Button
                    className='btn-pay-invoice'
                    variant='primary'
                    onClick={() => setShowPayInvoice(true)}
                  >
                    Pay Invoice
                  </Button>
                )}
              </>
            )}

            {/* invoice paid */}
            {isPaid && (
              <Button className='btn-payment' variant='success'>
                <Image
                  className='mr-2'
                  src='/assets/images/icons/success.svg'
                />
                Invoice Paid
              </Button>
            )}

            {/* no deposit and request approve */}
            {(isNoDeposit || isDeposit) && !metadata.approvedByClient && (
              <>
                <Button className='btn-payment' variant='warning'>
                  <Image
                    className='mr-2'
                    src='/assets/images/icons/warning.svg'
                  />
                  Needs Approval
                </Button>
                <Button
                  className='btn-pay-invoice'
                  variant='primary'
                  onClick={approveRecord}
                >
                  APPROVE ESTIMATE
                </Button>
              </>
            )}

            {/* no deposit and approved */}
            {isNoDeposit && metadata.approvedByClient && (
              <>
                <Button className='btn-payment' variant='success'>
                  <Image
                    className='mr-2'
                    src='/assets/images/icons/success.svg'
                  />
                  Approved for Work
                </Button>
              </>
            )}

            {/* invoice has deposit */}
            {isDeposit && metadata.approvedByClient && (
              <>
                <Button className='btn-payment' variant='warning'>
                  <Image
                    className='mr-2'
                    src='/assets/images/icons/warning.svg'
                  />
                  Deposit Due: ${formatAmount(deposit / 100)}
                </Button>
                {userID && (
                  <Button
                    className='btn-pay-invoice'
                    variant='primary'
                    onClick={() => setShowPayInvoice(true)}
                  >
                    Pay Deposit
                  </Button>
                )}
              </>
            )}
          </div>
        </Col>
      </Row>
    </div>
  )
}
export default CompanyInfo
