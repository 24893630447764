import { format } from '../utils/string'
import handlerError from './handlerError'

export default axios => {
  return {
    authorized: async ({ id }) => {
      return axios
        .get(format('/records/{id}?view=all', { id }))
        .then(res => res)
        .catch(handlerError)
    },
  }
}
