import {
  ACTION_REQUEST,
  INIT_RECORD,
  REQUEST_APPROVE_SUCCESS,
  REQUEST_ERROR,
  REQUEST_SUCCESS,
  REQUEST_PDF_SUCCESS,
} from '../types'

export const mainInitialState = {
  loading: true,
  user: null,
  error: null,
  company: null,
  record: null,
  success: {
    status: false,
    title: '',
    message: '',
  },
}

const mainReducer = (state = { ...mainInitialState }, action) => {
  switch (action.type) {
    case ACTION_REQUEST:
      return {
        ...state,
        success: {
          status: false,
          title: '',
          message: '',
        },
        loading: true,
      }
    case REQUEST_ERROR:
      const { error } = action
      return {
        ...state,
        loading: false,
        error: error,
      }
    case REQUEST_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
      }
    case INIT_RECORD:
      const { record } = action
      return {
        ...state,
        loading: false,
        record,
      }
    case REQUEST_PDF_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
      }

    case REQUEST_APPROVE_SUCCESS: {
      const { record } = action
      const newRecord = { ...state }.record
      newRecord.metadata = {
        ...newRecord.metadata,
        approvedByClient: record.metadata.approvedByClient,
      }
      return {
        ...state,
        loading: false,
        record: newRecord,
        success: {
          status: true,
          title: 'Approval Succesful',
          message: `Your approval has been successful. We will notify ${newRecord.metadata.company.name} that you have approved this estimate.`,
        },
      }
    }
    default:
      return state
  }
}

export default mainReducer
