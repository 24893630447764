import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import toast from '../toast'

import CompanyInfo from '../companyInfo'
import Estimate from '../estimate'
import PayInvoice from '../paymentModal'

const InvoiceDetail = () => {
  const [showPayInvoice, setShowPayInvoice] = useState(false)
  const { success, error } = useSelector(store => {
    const { main } = store
    return main
  })

  useEffect(() => {
    if (success.status) {
      toast.success({ title: success.title, body: success.message })
    }
    if (error) {
      toast.error({ title: 'Fail', body: error })
    }
  }, [success, error])
  return (
    <>
      <CompanyInfo setShowPayInvoice={setShowPayInvoice} />
      <Estimate />
      <PayInvoice
        open={showPayInvoice}
        hideModal={() => {
          setShowPayInvoice(false)
        }}
      />
    </>
  )
}

export default InvoiceDetail
