import { REQUEST_APPROVE, REQUEST_LOGIN, REQUEST_PDF } from '../types'

export function login(payload) {
  return { type: REQUEST_LOGIN, payload }
}

export function approve(payload) {
  return { type: REQUEST_APPROVE, payload }
}

export function getPdf(payload) {
  return { type: REQUEST_PDF, payload }
}
