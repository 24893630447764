import Axios from 'axios'
import cookie from 'js-cookie'

import { API_AUTH_METHOD, API_CONTENT_TYPE, API_URL } from '../config'
import auth from './auth'
import record from './record'
import payment from './payment'

export const apiConfig = {
  baseURL: API_URL,
  headers: {
    Accept: API_CONTENT_TYPE,
    'Content-Type': API_CONTENT_TYPE,
  },
}

const instance = Axios.create(apiConfig)

instance.interceptors.request.use(
  config => {
    if (cookie.get('token')) {
      config.headers.Authorization = `${API_AUTH_METHOD} ${cookie.get('token')}`
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

export const client = instance

export default () => {
  const authAPI = auth(client)
  const recordAPI = record(client)
  const paymentApi = payment(client)

  return {
    auth: authAPI,
    record: recordAPI,
    payment: paymentApi,
  }
}
