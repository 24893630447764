import Axios from 'axios'

import { format } from '../utils/string'
import handlerError from './handlerError'
import { DETAIL_API } from '../config'

export default axios => {
  return {
    get: async ({ recordId }) => {
      return axios
        .get(format('/records/{id}?view=all', { id: recordId }))
        .then(res => res)
        .catch(handlerError)
    },
    approve: async ({ recordId }) => {
      return axios
        .post(format('/estimates/{recordId}/approve?view=all', { recordId }))
        .then(res => {
          return res
        })
        .catch(handlerError)
    },
    pdf: async ({ recordId }) => {
      console.log('recordId', recordId)
      return axios
        .get(format('/records/{id}/pdf', { id: recordId }))
        .then(res => res)
        .catch(handlerError)
    },
  }
}
