// console.log('process.env', process.env)

export const DETAIL_API = process.env.REACT_APP_DETAIL_API

export const APP_URL = process.env.REACT_APP_ENV_CLIENT_PORTAL_DETAIL_API;

export const DEFAULT_LOCALE = process.env.REACT_APP_DEFAULT_LOCALE

export const API_URL = `${APP_URL}`
export const API_CONTENT_TYPE = 'application/json'
export const API_AUTH_HEADER = 'Authorization'
export const API_AUTH_METHOD = 'Record'
export const STRIPE_PUBLISHABLE_KEY =
  process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY

export const FILE_READ_METHODS = {
  readAsArrayBuffer: 'readAsArrayBuffer',
  readAsBinaryString: 'readAsBinaryString',
  readAsText: 'readAsText',
  readAsDataURL: 'readAsDataURL',
}
