import JSZip from 'jszip'
import { saveAs } from 'file-saver'
import FileType from 'file-type/browser'

import { FILE_READ_METHODS } from '../config'

export const readFileAsync = (
  file,
  method = FILE_READ_METHODS.readAsDataURL
) => {
  return new Promise((resolve, reject) => {
    let reader = new FileReader()

    reader.onload = () => {
      resolve(reader.result)
    }

    reader.onerror = reject

    return reader[method](file)
  })
}

export const downloadImage = path => {
  fetch(path, {
    method: 'GET',
    headers: {},
  })
    .then(response => {
      response.arrayBuffer().then(async buffer => {
        const blob = new Blob([buffer])
        const fileType = await FileType.fromBlob(blob)
        const url = window.URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `photo.${fileType.ext}`) // or any other extension
        document.body.appendChild(link)
        link.click()
      })
    })
    .catch(err => {
      console.log(err)
    })
}

export const downloadZipImagesPath = (zipName, arrayImages = []) => {
  if (arrayImages <= 0) return
  const zip = new JSZip()
  const tasks = []

  arrayImages.forEach(img => {
    const { fileName, path } = img
    tasks.push(
      new Promise((resolve, reject) => {
        return fetch(path)
          .then(response => response.blob())
          .then(async blob => {
            const fileType = await FileType.fromBlob(blob)
            if (fileType) {
              zip.file(fileName + '.' + fileType.ext, blob)
              resolve()
            } else {
              reject('cannot file')
            }
          })
          .catch(reject)
      })
    )
  })

  Promise.all(tasks).then(() =>
    zip
      .generateAsync({ type: 'blob' })
      .then(content => saveAs(content, `${zipName}.zip`))
  )
}
