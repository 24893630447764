import React, { useState } from 'react'
import { Image, Row } from 'react-bootstrap'
import { Box, Flex } from 'reflexbox'
import { useSelector } from 'react-redux'

import { downloadZipImagesPath } from '../../utils/image'
import VehiclePhotos from './vehicle.photos'
import ShowPhotoModal from './showPhotoModal'

import './vehicleDetail.scss'
import { formatAmount } from '../../utils/currency'

const VehicleDetail = () => {
  const { record } = useSelector(store => {
    const { main } = store
    return main
  })
  const [photosModal, setPhotoModal] = useState(null)
  const { vehicles, metadata } = record

  const downloadAll = vehiclePhotos => {
    const photos = vehiclePhotos.map(el => {
      const { isBefore, name } = el
      return {
        fileName: `${isBefore ? `before_${name}` : `after_${name}`}`,
        path: el.main,
      }
    })
    downloadZipImagesPath('vehicle photo', photos)
  }

  return (
    <>
      {photosModal && (
        <ShowPhotoModal
          downloadAll={downloadAll}
          company={metadata.company}
          document={metadata.document}
          show={photosModal}
          setShow={setPhotoModal}
        />
      )}
      {vehicles.map((vehicle, index) => {
        const num = (index + 1).toString().padStart(2, '0')
        const vehicleServices = vehicle.services
        const { packages, services } = vehicleServices
        const { photos } = vehicle
        const { image } = vehicle.vehicle
        let photoImages = []
        photos.forEach(el => {
          const {
            beforeURLThumbnail,
            beforeURL,
            afterURLThumbnail,
            afterURL,
          } = el
          if (beforeURL) {
            photoImages = photoImages.concat([
              {
                isBefore: true,
                name: el.name,
                thumbnail: beforeURLThumbnail,
                main: beforeURL,
              },
            ])
          }
          if (afterURL) {
            photoImages = photoImages.concat([
              {
                isBefore: false,
                name: el.name,
                thumbnail: afterURLThumbnail,
                main: afterURL,
              },
            ])
          }
        })
        return (
          <div className='vehicle-detail' key={index}>
            <Flex flexDirection='row' className='vehicle-detail-info'>
              <Box className='vehicle-detail-title'>
                {vehicles.length > 1 ? num : ''}
              </Box>
              <Box width='200' className='vehicle-detail-avatar'>
                <Image
                  className='d-100'
                  src={
                    image
                      ? image.replace('/color/', '/colorCropped/')
                      : '/assets/images/test/car1vehicle.svg'
                  }
                  alt=''
                />
              </Box>
              <Box flexGrow='1' className='vehicle-info'>
                <div className='vehicle-info-year'>
                  {vehicle.vehicle.info.year}
                </div>
                <h5 className='font-weight-bold'>
                  {vehicle.vehicle.info.make} {vehicle.vehicle.info.model}
                </h5>
                <div className='vehicle-stock'>
                  <p>
                    <span className='font-weight-600'>STOCK/RO:</span>&nbsp;
                    {(vehicle.vehicle.info.stock &&
                      vehicle.vehicle.info.stock) ||
                      'None Added'}
                  </p>
                  <p className='mobile-none'>
                    &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
                  </p>
                  <p>
                    <span className='font-weight-600'>VIN:</span>&nbsp;
                    {(vehicle.vehicle.info.vin && vehicle.vehicle.info.vin) ||
                      'None Added'}
                  </p>
                </div>
              </Box>
              <Box className='vehicle-price'>
                ${formatAmount(vehicle.total / 100)}
              </Box>
            </Flex>

            <div className='vehicle-title'>Services</div>
            <Flex className='vehicle-detail-list border-bottom'>
              <Row className='w-100 m-0'>
                {packages.map(pck => {
                  return (
                    <Flex
                      key={pck.name}
                      className='vehicle-detail-item col-12 col-lg-4'
                    >
                      <Box className='pl-2 pr-2 pt-1 pb-1 col-12 border d-flex align-items-center'>
                        <Box className='vehicle-detail-item-title'>
                          <div className='font-875 font-weight-bold'>
                            Package
                          </div>
                          <div className='font-75 text-secondary'>
                            ${formatAmount(pck.amount / 100)}
                          </div>
                        </Box>
                        <Box className='vehicle-detail-item-info'>
                          <div>{pck.name}</div>
                        </Box>
                      </Box>
                    </Flex>
                  )
                })}

                {services.map(ser => {
                  return (
                    <Flex
                      key={ser.name}
                      className='vehicle-detail-item col-12 col-lg-4'
                    >
                      <Box className='pl-2 pr-2 pt-1 pb-1 col-12 border d-flex align-items-center'>
                        <Box className='vehicle-detail-item-title'>
                          <div className='font-875 font-weight-bold'>
                            Services
                          </div>
                          <div className='font-75 text-secondary'>
                            ${formatAmount(ser.amount / 100)}
                          </div>
                        </Box>
                        <Box className='vehicle-detail-item-info'>
                          <div>{ser.name}</div>
                        </Box>
                      </Box>
                    </Flex>
                  )
                })}
              </Row>
            </Flex>

            <VehiclePhotos
              photoImages={photoImages}
              vehicleData={vehicle}
              setPhotoModal={setPhotoModal}
              downloadAll={downloadAll}
            />
          </div>
        )
      })}
    </>
  )
}
export default VehicleDetail
