import moment from 'moment'

export const dateFromNow = seconds =>
  new Date(new Date().getTime() + seconds * 1000)

export const formatDate = dateString => {
  return moment(dateString).format('MM/DD/YYYY')
}

export const formatDateTime = dateString => {
  return moment(dateString).format('MM/DD/YYYY - LT')
}
