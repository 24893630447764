import { useCookie } from 'react-use'
import Cookies from 'js-cookie'
import qs from 'qs'

const query = qs.parse(window.location.search, {
  ignoreQueryPrefix: true,
})
if (query.token) Cookies.set('token', query.token)
if ('token' in query && !query.token) Cookies.remove('token')

export default () => {
  const [token, setToken, deleteToken] = useCookie('token')

  return [token, setToken, deleteToken]
}
