export const ACTION_REQUEST = 'ACTION_REQUEST'
export const REQUEST_ERROR = 'REQUEST_ERROR'
export const REQUEST_SUCCESS = 'REQUEST_SUCCESS'
export const REQUEST_LOGIN = 'REQUEST_LOGIN'
export const INIT_RECORD = 'INIT_RECORD'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const UNAUTHORIZED_FAILURE = 'UNAUTHORIZED_FAILURE'

export const REQUEST_APPROVE = 'REQUEST_APPROVE'
export const REQUEST_APPROVE_SUCCESS = 'REQUEST_APPROVE_SUCCESS'
export const REQUEST_APPROVE_FAILURE = 'REQUEST_APPROVE_FAILURE'

export const REQUEST_PDF = 'REQUEST_PDF'
export const REQUEST_PDF_SUCCESS = 'REQUEST_PDF_SUCCESS'
export const REQUEST_PDF_FAILURE = 'REQUEST_PDF_FAILURE'

export const REQUEST_GET_RECORD = 'REQUEST_GET_RECORD'
