import React, { useEffect, useState } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { InvoiceDetail, NotFoundPage } from './containers'
import { PrivateRoute } from './containers/routes'
import userApi from './api/useApi'
import { INIT_RECORD, REQUEST_ERROR } from './store/types'
import { Spinner } from 'react-bootstrap'

const Public = () => {
  return (
    <Router>
      <Switch>
        <Route component={NotFoundPage} />
      </Switch>
    </Router>
  )
}

const Private = () => {
  return (
    <Router>
      <Switch>
        <PrivateRoute exact path='/:id' component={InvoiceDetail} />
      </Switch>
    </Router>
  )
}

function App() {
  const { loading } = useSelector(store => store.main)
  const dispatch = useDispatch()
  const { auth } = userApi()

  const [isPrivate, setPrivate] = useState(null)

  useEffect(() => {
    const id = window.location.pathname.substring(1)
    auth
      .authorized({ id })
      .then(res => {
        dispatch({ type: INIT_RECORD, record: res.data })
        setPrivate('private')
      })
      .catch(error => {
        dispatch({ type: REQUEST_ERROR, error })
        setPrivate('public')
      })
  }, [])

  return (
    <>
      {loading && (
        <div className='spinner-app'>
          <Spinner animation='border' role='status' />
        </div>
      )}
      {isPrivate === 'public' && <Public />}
      {isPrivate === 'private' && <Private />}
    </>
  )
}

export default App
