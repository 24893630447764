import { format } from 'mathjs'

export const formatFloating = value => {
  if (!value) return '0.00'

  return format(value, { notation: 'fixed', precision: 2 })
}

export function formatAmount(amount = 0) {
  return amount.toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })
}

export const formatCurrency = (number, currency) => {
  let dot = '.'
  let comma = ','
  let sign = comma
  switch (currency) {
    case 'gbp':
      currency = '£'
      sign = dot
      break
    case 'chf':
      currency = 'CHF'
      break
    case 'eur':
      currency = '€'
      break
    case 'zar':
      currency = 'R'
      break
    case 'brl':
      currency = 'R$'
      break
    case 'try':
      currency = '₺'
      break
    default:
      currency = '$'
      break
  }
  number = Number(number)
  var parts = formatFloating(number).split('.')
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, sign)
  return `${currency}${parts.join(sign == dot ? comma : dot)}`
}
