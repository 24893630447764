import { DOCUMENT_TYPE } from './constant'

export const isNoDepositPayment = record => {
  const { payment, metadata } = record
  const { deposit = 0 } = payment
  return deposit <= 0 && metadata.document === DOCUMENT_TYPE.ESTIMATE
}

export const isDepositPayment = record => {
  const { payment, total, metadata } = record
  const { deposit = 0 } = payment
  return (
    deposit > 0 && total > 0 && metadata.document === DOCUMENT_TYPE.ESTIMATE
  )
}

export const isDepositPartialPayment = record => {
  const { payment, metadata } = record
  const { deposit = 0, amount } = payment
  return deposit < amount && metadata.document === DOCUMENT_TYPE.ESTIMATE
}

export const isDepositPaidPayment = record => {
  const { payment, metadata } = record
  const { deposit = 0, amount } = payment
  return (
    isDepositPayment(record) &&
    deposit === amount &&
    metadata.document === DOCUMENT_TYPE.ESTIMATE
  )
}

export const isPaidPayment = record => {
  const { payment, total, metadata } = record
  const { amount } = payment
  return (
    total > 0 && amount >= total && metadata.document === DOCUMENT_TYPE.INVOICE
  )
}

export const isPartialPaidPayment = record => {
  const { payment, total, metadata } = record
  const { amount } = payment
  return (
    amount > 0 && amount < total && metadata.document === DOCUMENT_TYPE.INVOICE
  )
}

export const isUnpaidPayment = record => {
  const { payment, metadata } = record
  const { amount = 0 } = payment
  return amount <= 0 && metadata.document === DOCUMENT_TYPE.INVOICE
}
