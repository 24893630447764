import React from 'react'
import { Container, Image } from 'react-bootstrap'

import './layout.scss'

export default ({ children }) => {
  // const [isShowMenu, setShowHide] = useState(false)
  return (
    <>
      <div className='layout-default'>
        <header className='header'>
          <Container className='d-flex align-items-center'>
            <Image className='logo' src='/assets/images/logo.svg' />
            {/* <Button
              className='btn-mobile'
              onClick={() => setShowHide(!isShowMenu)}
            >
              <Image src='/assets/images/icons/menu-mobile.svg' alt='' />
            </Button> */}
          </Container>
        </header>

        <main className='main'>
          <div className='container'>{children}</div>
        </main>

        <footer className='footer'>
          <Image src='/assets/images/footerlogo.svg' />
          <div className='text-center font-75'>
            Powered by Mobile Tech RX
            <br />
            Mobitech International Inc. | © 2020
          </div>
        </footer>
      </div>
    </>
  )
}
