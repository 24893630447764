import { format, formatQuery } from '../utils/string'

export default axios => {
  return {
    createPayment: ({ id, ...query }) => {
      const path = `${format(`/records/{id}/payment`, { id })}?${formatQuery(
        query
      )}`
      return axios.get(path)
    },
    sendReceipt: (recordId, params) => {
      const path = `${format(`/records/{id}/paymentReceipt`, {
        id: recordId,
      })}?${formatQuery(params)}`
      return axios.get(path)
    },
  }
}
