import cookie from 'js-cookie'

export default error => {
  console.log('error', error.response)
  if (error.response) {
    const { status } = error.response
    if (status === 401) {
      
      cookie.remove('token')
      window.location.href = '/'
    }
  } else {
    return error
  }
}
