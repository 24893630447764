import qs from 'qs'

export const random = (length = 6) =>
  Math.random().toString(20).substr(2, length)

export const format = (string, ...args) => {
  if (!args.length) return string

  let s = string
  const a =
    'string' === typeof args[0] || 'number' === typeof args[0]
      ? Array.prototype.slice.call(args)
      : args[0]

  for (let key in a) {
    s = s.replace(new RegExp('\\{' + key + '\\}', 'gi'), a[key])
  }

  return s
}

export const formatQuery = data => {
  return qs.stringify(data)
}

export const getInitials = name => {
  const arr = name.split(' ')
  const regex = /^[\w\d]+$/
  let tmp = []
  arr.map(str => {
    if (regex.test(str)) {
      tmp[tmp.length] = str
    }
    return ''
  })
  if (tmp.length === 0) {
    tmp = arr
  }

  let result = ''
  if (tmp.length > 0) {
    result = tmp[0].charAt(0)
    if (tmp[1]) {
      result += tmp[1].charAt(0)
    } else {
      result += tmp[0].charAt(1)
    }
  }
  return result.toUpperCase()
}
