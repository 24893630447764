import React from 'react'
import { toast } from 'react-toastify'

const notify = ({ title, body }) => {
  return (
    <div>
      <div className='h5 font-weight-bold'>{title}</div>
      <div>{body}</div>
    </div>
  )
}

export default {
  success: content => toast.success(notify(content)),
  info: content => toast.info(notify(content)),
  warning: content => toast.warning(notify(content)),
  error: content => toast.error(notify(content)),
  dark: content => toast.dark(notify(content)),
}
